import { animateIntoView } from "@doubleedesign/animate-into-view/src/animate-into-view";

window.addEventListener('scroll', function () {
    stickyHeader();
    activeMenuItemClass();
});

document.addEventListener('DOMContentLoaded', function() {
    animate();
});

function stickyHeader() {
    const header = document.querySelector('.site-header')
    if (window.scrollY > 0) {
        header.classList.add('site-header--sticky');
    } else {
        header.classList.remove('site-header--sticky');
    }
}

function activeMenuItemClass() {
    const sections = document.querySelectorAll("section");
    const menuLinks = document.querySelectorAll(".site-header a");
    let closestSectionId = "";
    let minDistanceToTop = window.innerHeight; // Start with a high initial value

    sections.forEach((section) => {
        const sectionTop = section.getBoundingClientRect().top;

        // Find the section closest to the top of the viewport (or within a small offset)
        if (sectionTop >= 0 && sectionTop < minDistanceToTop) {
            minDistanceToTop = sectionTop;
            closestSectionId = section.getAttribute('id');
        }
    });

    // Update active class on menu links
    menuLinks.forEach((link) => {
        link.classList.remove('active');
        if (link.getAttribute('href') === `#${closestSectionId}`) {
            link.classList.add('active');
        }
    });
}

function animate() {
    animateIntoView({
        selector: '.site-header, .copy-image__copy, .list-group + blockquote',
        threshold: 0.75,
        type: 'fadeInDown'
    });

    animateIntoView({
        selector: '.hero__image, .copy-image .badges, .copy-image__image',
        threshold: 0.5,
        type: 'fadeIn'
    });

    animateIntoView({
        selector: '.hero__copy',
        threshold: 0.75,
        type: 'fadeInRight'
    });

    animateIntoView({
        selector: '.call-to-action, .list-group__item',
        threshold: 0.5,
        type: 'fadeInUp'
    });

    animateIntoView({
        selector: '.site-footer',
        threshold: 0.5,
        type: 'fadeInUp'
    });

    animateIntoView({
        selector: '.image-grid figure',
        threshold: 0.5,
        type: 'fadeIn'
    })
}